import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://273b5bb5fad161b1169d0281182aa3de@o4506263491641344.ingest.sentry.io/4506263508877312",
  debug: false,
  environment: "production",
  release: "landing@1.4.0",
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});